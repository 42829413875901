import Timezone from './Timezone';

const timezones : Timezone[] = [
	{Name:'Africa/Abidjan', Country:'CI',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Accra', Country:'GH',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Addis_Ababa', Country:'ET',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Africa/Algiers', Country:'DZ',Key:'CET',Start:357523200,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Asmara', Country:'ER',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Africa/Bamako', Country:'ML',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Bangui', Country:'CF',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Banjul', Country:'GM',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Bissau', Country:'GW',Key:'GMT',Start:157770000,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Blantyre', Country:'MW',Key:'CAT',Start:-2109291020,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Brazzaville', Country:'CG',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Bujumbura', Country:'BI',Key:'CAT',Start:-2109291020,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Cairo', Country:'EG',Key:'EET',Start:1411678800,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Casablanca', Country:'MA',Key:'WEST',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Ceuta', Country:'ES',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Conakry', Country:'GN',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Dakar', Country:'SN',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Dar_es_Salaam', Country:'TZ',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Africa/Djibouti', Country:'DJ',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Africa/Douala', Country:'CM',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/El_Aaiun', Country:'MA',Key:'WET',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:true},
	{Name:'Africa/Freetown', Country:'SL',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Gaborone', Country:'BW',Key:'CAT',Start:-2109291020,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Harare', Country:'ZW',Key:'CAT',Start:-2109291020,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Johannesburg', Country:'ZA',Key:'SAST',Start:-813805200,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Juba', Country:'SS',Key:'CAT',Start:1612126800,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Kampala', Country:'UG',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Africa/Khartoum', Country:'SD',Key:'CAT',Start:1509483600,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Kigali', Country:'RW',Key:'CAT',Start:-2109291020,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Kinshasa', Country:'CD',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Lagos', Country:'NG',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Libreville', Country:'GA',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Lome', Country:'TG',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Luanda', Country:'AO',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Lubumbashi', Country:'CD',Key:'CAT',Start:-2109291020,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Lusaka', Country:'ZM',Key:'CAT',Start:-2109291020,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Malabo', Country:'GQ',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Maputo', Country:'MZ',Key:'CAT',Start:-2109291020,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Maseru', Country:'LS',Key:'SAST',Start:-813805200,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Mbabane', Country:'SZ',Key:'SAST',Start:-813805200,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Mogadishu', Country:'SO',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Africa/Monrovia', Country:'LR',Key:'GMT',Start:63593070,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Nairobi', Country:'KE',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Africa/Ndjamena', Country:'TD',Key:'WAT',Start:321314400,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Niamey', Country:'NE',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Nouakchott', Country:'MR',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Ouagadougou', Country:'BF',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Porto-Novo', Country:'BJ',Key:'WAT',Start:-1588465800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Sao_Tome', Country:'ST',Key:'GMT',Start:1546304400,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Africa/Tripoli', Country:'LY',Key:'EET',Start:1382659200,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Africa/Tunis', Country:'TN',Key:'CET',Start:1224982800,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Africa/Windhoek', Country:'NA',Key:'CAT',Start:1504400400,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'America/Adak', Country:'US',Key:'HST',Start:2147483647,OffsetInSeconds:-36000,DaylighSavingTime:false},
	{Name:'America/Anchorage', Country:'US',Key:'AKST',Start:2147483647,OffsetInSeconds:-32400,DaylighSavingTime:false},
	{Name:'America/Anguilla', Country:'AI',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Antigua', Country:'AG',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Araguaina', Country:'BR',Key:'BRT',Start:1361066400,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/Buenos_Aires', Country:'AR',Key:'ART',Start:1237082400,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/Catamarca', Country:'AR',Key:'ART',Start:1205632800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/Cordoba', Country:'AR',Key:'ART',Start:1237082400,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/Jujuy', Country:'AR',Key:'ART',Start:1205632800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/La_Rioja', Country:'AR',Key:'ART',Start:1205632800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/Mendoza', Country:'AR',Key:'ART',Start:1205632800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/Rio_Gallegos', Country:'AR',Key:'ART',Start:1205632800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/Salta', Country:'AR',Key:'ART',Start:1205632800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/San_Juan', Country:'AR',Key:'ART',Start:1205632800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/San_Luis', Country:'AR',Key:'ART',Start:1255233600,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/Tucuman', Country:'AR',Key:'ART',Start:1237082400,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Argentina/Ushuaia', Country:'AR',Key:'ART',Start:1205632800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Aruba', Country:'AW',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Asuncion', Country:'PY',Key:'PYST',Start:2147483647,OffsetInSeconds:-10800,DaylighSavingTime:true},
	{Name:'America/Atikokan', Country:'CA',Key:'EST',Start:-1946918424,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Bahia', Country:'BR',Key:'BRT',Start:1330221600,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Bahia_Banderas', Country:'MX',Key:'CST',Start:1667113200,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Barbados', Country:'BB',Key:'AST',Start:338706000,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Belem', Country:'BR',Key:'BRT',Start:571197600,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Belize', Country:'BZ',Key:'CST',Start:413874000,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Blanc-Sablon', Country:'CA',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Boa_Vista', Country:'BR',Key:'AMT',Start:971578800,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Bogota', Country:'CO',Key:'COT',Start:729057600,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Boise', Country:'US',Key:'MST',Start:2147483647,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Cambridge_Bay', Country:'CA',Key:'MST',Start:2147483647,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Campo_Grande', Country:'BR',Key:'AMT',Start:1550372400,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Cancun', Country:'MX',Key:'EST',Start:1422777600,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Caracas', Country:'VE',Key:'VET',Start:1462086000,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Cayenne', Country:'GF',Key:'GFT',Start:-71092800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Cayman', Country:'KY',Key:'EST',Start:-1946918424,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Chicago', Country:'US',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Chihuahua', Country:'MX',Key:'CST',Start:1667116800,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Ciudad_Juarez', Country:'MX',Key:'MST',Start:2147483647,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Costa_Rica', Country:'CR',Key:'CST',Start:700635600,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Creston', Country:'CA',Key:'MST',Start:-68659200,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Cuiaba', Country:'BR',Key:'BRT',Start:1550372400,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Curacao', Country:'CW',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Danmarkshavn', Country:'GL',Key:'GMT',Start:820465200,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'America/Dawson', Country:'CA',Key:'MST',Start:1604214000,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Dawson_Creek', Country:'CA',Key:'MST',Start:84013200,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Denver', Country:'US',Key:'MST',Start:2147483647,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Detroit', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Dominica', Country:'DM',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Edmonton', Country:'CA',Key:'MST',Start:2147483647,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Eirunepe', Country:'BR',Key:'ACT',Start:1384056000,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/El_Salvador', Country:'SV',Key:'CST',Start:591166800,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Fort_Nelson', Country:'CA',Key:'MST',Start:1425808800,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Fortaleza', Country:'BR',Key:'BRT',Start:1013911200,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Glace_Bay', Country:'CA',Key:'AST',Start:2147483647,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Goose_Bay', Country:'CA',Key:'AST',Start:2147483647,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Grand_Turk', Country:'TC',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Grenada', Country:'GD',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Guadeloupe', Country:'GP',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Guatemala', Country:'GT',Key:'CST',Start:1159678800,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Guayaquil', Country:'EC',Key:'ECT',Start:728884800,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Guyana', Country:'GY',Key:'GYT',Start:701841600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Halifax', Country:'CA',Key:'AST',Start:2147483647,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Havana', Country:'CU',Key:'CST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Hermosillo', Country:'MX',Key:'MST',Start:909302400,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Indiana/Indianapolis', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Indiana/Knox', Country:'US',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Indiana/Marengo', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Indiana/Petersburg', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Indiana/Tell_City', Country:'US',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Indiana/Vevay', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Indiana/Vincennes', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Indiana/Winamac', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Inuvik', Country:'CA',Key:'MST',Start:2147483647,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Iqaluit', Country:'CA',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Jamaica', Country:'JM',Key:'EST',Start:436341600,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Juneau', Country:'US',Key:'AKST',Start:2147483647,OffsetInSeconds:-32400,DaylighSavingTime:false},
	{Name:'America/Kentucky/Louisville', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Kentucky/Monticello', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Kralendijk', Country:'BQ',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/La_Paz', Country:'BO',Key:'BOT',Start:-1192307244,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Lima', Country:'PE',Key:'PET',Start:765172800,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Los_Angeles', Country:'US',Key:'PST',Start:2147483647,OffsetInSeconds:-28800,DaylighSavingTime:false},
	{Name:'America/Lower_Princes', Country:'SX',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Maceio', Country:'BR',Key:'BRT',Start:1013911200,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Managua', Country:'NI',Key:'CST',Start:1159682400,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Manaus', Country:'BR',Key:'AMT',Start:761713200,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Marigot', Country:'MF',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Martinique', Country:'MQ',Key:'AST',Start:338958000,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Matamoros', Country:'MX',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Mazatlan', Country:'MX',Key:'MST',Start:1667116800,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Menominee', Country:'US',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Merida', Country:'MX',Key:'CST',Start:1667113200,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Metlakatla', Country:'US',Key:'AKST',Start:2147483647,OffsetInSeconds:-32400,DaylighSavingTime:false},
	{Name:'America/Mexico_City', Country:'MX',Key:'CST',Start:1667113200,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Miquelon', Country:'PM',Key:'PMDT',Start:2147483647,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Moncton', Country:'CA',Key:'AST',Start:2147483647,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Monterrey', Country:'MX',Key:'CST',Start:1667113200,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Montevideo', Country:'UY',Key:'UYT',Start:1425787200,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Montserrat', Country:'MS',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Nassau', Country:'BS',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/New_York', Country:'US',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Nome', Country:'US',Key:'AKST',Start:2147483647,OffsetInSeconds:-32400,DaylighSavingTime:false},
	{Name:'America/Noronha', Country:'BR',Key:'FNT',Start:1013907600,OffsetInSeconds:-7200,DaylighSavingTime:false},
	{Name:'America/North_Dakota/Beulah', Country:'US',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/North_Dakota/Center', Country:'US',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/North_Dakota/New_Salem', Country:'US',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Nuuk', Country:'GL',Key:'WGT',Start:1679792400,OffsetInSeconds:-7200,DaylighSavingTime:false},
	{Name:'America/Ojinaga', Country:'MX',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Panama', Country:'PA',Key:'EST',Start:-1946918424,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Paramaribo', Country:'SR',Key:'SRT',Start:465449400,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Phoenix', Country:'US',Key:'MST',Start:-68659200,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Port-au-Prince', Country:'HT',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Port_of_Spain', Country:'TT',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Porto_Velho', Country:'BR',Key:'AMT',Start:571201200,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Puerto_Rico', Country:'PR',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Punta_Arenas', Country:'CL',Key:'CLT',Start:1480820400,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Rankin_Inlet', Country:'CA',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Recife', Country:'BR',Key:'BRT',Start:1013911200,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Regina', Country:'CA',Key:'CST',Start:-305737200,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Resolute', Country:'CA',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Rio_Branco', Country:'BR',Key:'ACT',Start:1384056000,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Santarem', Country:'BR',Key:'BRT',Start:1214280000,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Santiago', Country:'CL',Key:'CLST',Start:2147483647,OffsetInSeconds:-10800,DaylighSavingTime:true},
	{Name:'America/Santo_Domingo', Country:'DO',Key:'AST',Start:975823200,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Sao_Paulo', Country:'BR',Key:'BRT',Start:1550368800,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'America/Scoresbysund', Country:'GL',Key:'EGT',Start:2147483647,OffsetInSeconds:-3600,DaylighSavingTime:false},
	{Name:'America/Sitka', Country:'US',Key:'AKST',Start:2147483647,OffsetInSeconds:-32400,DaylighSavingTime:false},
	{Name:'America/St_Barthelemy', Country:'BL',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/St_Johns', Country:'CA',Key:'NST',Start:2147483647,OffsetInSeconds:-12600,DaylighSavingTime:false},
	{Name:'America/St_Kitts', Country:'KN',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/St_Lucia', Country:'LC',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/St_Thomas', Country:'VI',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/St_Vincent', Country:'VC',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Swift_Current', Country:'CA',Key:'CST',Start:73472400,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Tegucigalpa', Country:'HN',Key:'CST',Start:1154926800,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Thule', Country:'GL',Key:'AST',Start:2147483647,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Tijuana', Country:'MX',Key:'PST',Start:2147483647,OffsetInSeconds:-28800,DaylighSavingTime:false},
	{Name:'America/Toronto', Country:'CA',Key:'EST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:false},
	{Name:'America/Tortola', Country:'VG',Key:'AST',Start:-765399600,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'America/Vancouver', Country:'CA',Key:'PST',Start:2147483647,OffsetInSeconds:-28800,DaylighSavingTime:false},
	{Name:'America/Whitehorse', Country:'CA',Key:'MST',Start:1604214000,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'America/Winnipeg', Country:'CA',Key:'CST',Start:2147483647,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'America/Yakutat', Country:'US',Key:'AKST',Start:2147483647,OffsetInSeconds:-32400,DaylighSavingTime:false},
	{Name:'America/Yellowknife', Country:'CA',Key:'MST',Start:2147483647,OffsetInSeconds:-25200,DaylighSavingTime:false},
	{Name:'Antarctica/Casey', Country:'AQ',Key:'CAST',Start:1601740860,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Antarctica/Davis', Country:'AQ',Key:'DAVT',Start:1329854400,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Antarctica/DumontDUrville', Country:'AQ',Key:'LMT',Start:-2147483648,OffsetInSeconds:35320,DaylighSavingTime:false},
	{Name:'Antarctica/Macquarie', Country:'AU',Key:'AEDT',Start:2147483647,OffsetInSeconds:39600,DaylighSavingTime:true},
	{Name:'Antarctica/Mawson', Country:'AQ',Key:'MAWT',Start:1255809600,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Antarctica/McMurdo', Country:'AQ',Key:'NZDT',Start:2147483647,OffsetInSeconds:46800,DaylighSavingTime:true},
	{Name:'Antarctica/Palmer', Country:'AQ',Key:'CLST',Start:1471147200,OffsetInSeconds:-10800,DaylighSavingTime:true},
	{Name:'Antarctica/Rothera', Country:'AQ',Key:'ART',Start:218246400,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'Antarctica/Syowa', Country:'AQ',Key:'SYOT',Start:-719636812,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Antarctica/Troll', Country:'AQ',Key:'GMT',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Antarctica/Vostok', Country:'AQ',Key:'VOST',Start:-1325483420,OffsetInSeconds:21600,DaylighSavingTime:false},
	{Name:'Arctic/Longyearbyen', Country:'SJ',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Asia/Aden', Country:'YE',Key:'AST',Start:-719636812,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Asia/Almaty', Country:'KZ',Key:'ALMT',Start:1099166400,OffsetInSeconds:21600,DaylighSavingTime:false},
	{Name:'Asia/Amman', Country:'JO',Key:'EET',Start:1666908000,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Asia/Anadyr', Country:'RU',Key:'ANAT',Start:1301151600,OffsetInSeconds:43200,DaylighSavingTime:false},
	{Name:'Asia/Aqtau', Country:'KZ',Key:'AQTT',Start:1099173600,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Aqtobe', Country:'KZ',Key:'AQTT',Start:1099170000,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Ashgabat', Country:'TM',Key:'TMT',Start:695772000,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Atyrau', Country:'KZ',Key:'AQTT',Start:1099173600,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Baghdad', Country:'IQ',Key:'AST',Start:1191196800,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Asia/Bahrain', Country:'BH',Key:'AST',Start:76190400,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Asia/Baku', Country:'AZ',Key:'AZT',Start:1445731200,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Asia/Bangkok', Country:'TH',Key:'ICT',Start:-1570084924,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Barnaul', Country:'RU',Key:'MSK+4',Start:1459022400,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Beirut', Country:'LB',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Asia/Bishkek', Country:'KG',Key:'KGT',Start:1123783200,OffsetInSeconds:21600,DaylighSavingTime:false},
	{Name:'Asia/Brunei', Country:'BN',Key:'BNT',Start:-767005200,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Chita', Country:'RU',Key:'YAKT',Start:1459015200,OffsetInSeconds:32400,DaylighSavingTime:false},
	{Name:'Asia/Choibalsan', Country:'MN',Key:'CHOT',Start:1474642800,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Colombo', Country:'LK',Key:'IST',Start:1145039400,OffsetInSeconds:19800,DaylighSavingTime:false},
	{Name:'Asia/Damascus', Country:'SY',Key:'EET',Start:1666904400,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Asia/Dhaka', Country:'BD',Key:'BST',Start:1262278800,OffsetInSeconds:21600,DaylighSavingTime:false},
	{Name:'Asia/Dili', Country:'TL',Key:'TLT',Start:969120000,OffsetInSeconds:32400,DaylighSavingTime:false},
	{Name:'Asia/Dubai', Country:'AE',Key:'GST',Start:-1577936472,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Asia/Dushanbe', Country:'TJ',Key:'TJT',Start:684363600,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Famagusta', Country:'CY',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Asia/Gaza', Country:'PS',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Asia/Hebron', Country:'PS',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Asia/Ho_Chi_Minh', Country:'VN',Key:'IDT',Start:171820800,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Hong_Kong', Country:'HK',Key:'HKT',Start:309292200,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Hovd', Country:'MN',Key:'HOVT',Start:1474646400,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Irkutsk', Country:'RU',Key:'IRKT',Start:1414256400,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Jakarta', Country:'ID',Key:'WIB',Start:-189415800,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Jayapura', Country:'ID',Key:'WIT',Start:-189423000,OffsetInSeconds:32400,DaylighSavingTime:false},
	{Name:'Asia/Jerusalem', Country:'IL',Key:'IST',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Asia/Kabul', Country:'AF',Key:'AFT',Start:-788932800,OffsetInSeconds:16200,DaylighSavingTime:false},
	{Name:'Asia/Kamchatka', Country:'RU',Key:'PETT',Start:1301151600,OffsetInSeconds:43200,DaylighSavingTime:false},
	{Name:'Asia/Karachi', Country:'PK',Key:'PKT',Start:1257012000,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Kathmandu', Country:'NP',Key:'NPT',Start:504901800,OffsetInSeconds:20700,DaylighSavingTime:false},
	{Name:'Asia/Khandyga', Country:'RU',Key:'YAKT',Start:1414252800,OffsetInSeconds:32400,DaylighSavingTime:false},
	{Name:'Asia/Kolkata', Country:'IN',Key:'IST',Start:-764145000,OffsetInSeconds:19800,DaylighSavingTime:false},
	{Name:'Asia/Krasnoyarsk', Country:'RU',Key:'KRAT',Start:1414260000,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Kuala_Lumpur', Country:'MY',Key:'MALT',Start:378662400,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Kuching', Country:'MY',Key:'MYT',Start:-767005200,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Kuwait', Country:'KW',Key:'AST',Start:-719636812,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Asia/Macau', Country:'MO',Key:'CST',Start:309292200,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Magadan', Country:'RU',Key:'MAGT',Start:1461427200,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Asia/Makassar', Country:'ID',Key:'WITA',Start:-766054800,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Manila', Country:'PH',Key:'PST',Start:275151600,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Muscat', Country:'OM',Key:'GST',Start:-1577936472,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Asia/Nicosia', Country:'CY',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Asia/Novokuznetsk', Country:'RU',Key:'KRAT',Start:1301169600,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Novosibirsk', Country:'RU',Key:'NOVT',Start:1469304000,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Omsk', Country:'RU',Key:'OMST',Start:1414263600,OffsetInSeconds:21600,DaylighSavingTime:false},
	{Name:'Asia/Oral', Country:'KZ',Key:'ORAT',Start:1099173600,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Phnom_Penh', Country:'KH',Key:'ICT',Start:-1570084924,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Pontianak', Country:'ID',Key:'WIB',Start:567964800,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Pyongyang', Country:'KP',Key:'KST',Start:1525446000,OffsetInSeconds:32400,DaylighSavingTime:false},
	{Name:'Asia/Qatar', Country:'QA',Key:'AST',Start:76190400,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Asia/Qostanay', Country:'KZ',Key:'QYZT',Start:1099170000,OffsetInSeconds:21600,DaylighSavingTime:false},
	{Name:'Asia/Qyzylorda', Country:'KZ',Key:'MSK+2',Start:1545328800,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Riyadh', Country:'SA',Key:'AST',Start:-719636812,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Asia/Sakhalin', Country:'RU',Key:'SAKT',Start:1459008000,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Asia/Samarkand', Country:'UZ',Key:'UZT',Start:686091600,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Seoul', Country:'KR',Key:'KST',Start:592333200,OffsetInSeconds:32400,DaylighSavingTime:false},
	{Name:'Asia/Shanghai', Country:'CN',Key:'CST',Start:684867600,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Singapore', Country:'SG',Key:'SST',Start:378662400,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Srednekolymsk', Country:'RU',Key:'SRET',Start:1414245600,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Asia/Taipei', Country:'TW',Key:'CST',Start:307551600,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Tashkent', Country:'UZ',Key:'UZT',Start:686091600,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Tbilisi', Country:'GE',Key:'GET',Start:1111878000,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Asia/Tehran', Country:'IR',Key:'IRDT',Start:1663788600,OffsetInSeconds:12600,DaylighSavingTime:false},
	{Name:'Asia/Thimphu', Country:'BT',Key:'BTT',Start:560025000,OffsetInSeconds:21600,DaylighSavingTime:false},
	{Name:'Asia/Tokyo', Country:'JP',Key:'JST',Start:-577962000,OffsetInSeconds:32400,DaylighSavingTime:false},
	{Name:'Asia/Tomsk', Country:'RU',Key:'MSD+3',Start:1464465600,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Ulaanbaatar', Country:'MN',Key:'ULAT',Start:1474642800,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Asia/Urumqi', Country:'CN',Key:'URUT',Start:-1325483420,OffsetInSeconds:21600,DaylighSavingTime:false},
	{Name:'Asia/Ust-Nera', Country:'RU',Key:'VLAT',Start:1414249200,OffsetInSeconds:36000,DaylighSavingTime:false},
	{Name:'Asia/Vientiane', Country:'LA',Key:'ICT',Start:-1570084924,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Asia/Vladivostok', Country:'RU',Key:'VLAT',Start:1414249200,OffsetInSeconds:36000,DaylighSavingTime:false},
	{Name:'Asia/Yakutsk', Country:'RU',Key:'YAKT',Start:1414252800,OffsetInSeconds:32400,DaylighSavingTime:false},
	{Name:'Asia/Yangon', Country:'MM',Key:'MMT',Start:-778410000,OffsetInSeconds:23400,DaylighSavingTime:false},
	{Name:'Asia/Yekaterinburg', Country:'RU',Key:'YEKT',Start:1414267200,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Asia/Yerevan', Country:'AM',Key:'AMT',Start:1319925600,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Atlantic/Azores', Country:'PT',Key:'AZOT',Start:2147483647,OffsetInSeconds:-3600,DaylighSavingTime:false},
	{Name:'Atlantic/Bermuda', Country:'BM',Key:'AST',Start:2147483647,OffsetInSeconds:-14400,DaylighSavingTime:false},
	{Name:'Atlantic/Canary', Country:'ES',Key:'WET',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Atlantic/Cape_Verde', Country:'CV',Key:'CVT',Start:186120000,OffsetInSeconds:-3600,DaylighSavingTime:false},
	{Name:'Atlantic/Faroe', Country:'FO',Key:'WET',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Atlantic/Madeira', Country:'PT',Key:'WET',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Atlantic/Reykjavik', Country:'IS',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Atlantic/South_Georgia', Country:'GS',Key:'LMT',Start:-2147483648,OffsetInSeconds:-8768,DaylighSavingTime:false},
	{Name:'Atlantic/St_Helena', Country:'SH',Key:'GMT',Start:-1830383032,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Atlantic/Stanley', Country:'FK',Key:'FKT',Start:1283666400,OffsetInSeconds:-10800,DaylighSavingTime:false},
	{Name:'Australia/Adelaide', Country:'AU',Key:'ACDT',Start:2147483647,OffsetInSeconds:37800,DaylighSavingTime:true},
	{Name:'Australia/Brisbane', Country:'AU',Key:'AEST',Start:699379200,OffsetInSeconds:36000,DaylighSavingTime:false},
	{Name:'Australia/Broken_Hill', Country:'AU',Key:'ACDT',Start:2147483647,OffsetInSeconds:37800,DaylighSavingTime:true},
	{Name:'Australia/Darwin', Country:'AU',Key:'ACST',Start:-813223800,OffsetInSeconds:34200,DaylighSavingTime:false},
	{Name:'Australia/Eucla', Country:'AU',Key:'ACWST',Start:1238260500,OffsetInSeconds:31500,DaylighSavingTime:false},
	{Name:'Australia/Hobart', Country:'AU',Key:'AEDT',Start:2147483647,OffsetInSeconds:39600,DaylighSavingTime:true},
	{Name:'Australia/Lindeman', Country:'AU',Key:'AEST',Start:762883200,OffsetInSeconds:36000,DaylighSavingTime:false},
	{Name:'Australia/Lord_Howe', Country:'AU',Key:'LHDT',Start:2147483647,OffsetInSeconds:39600,DaylighSavingTime:true},
	{Name:'Australia/Melbourne', Country:'AU',Key:'AEDT',Start:2147483647,OffsetInSeconds:39600,DaylighSavingTime:true},
	{Name:'Australia/Perth', Country:'AU',Key:'AWST',Start:1238263200,OffsetInSeconds:28800,DaylighSavingTime:false},
	{Name:'Australia/Sydney', Country:'AU',Key:'AEDT',Start:2147483647,OffsetInSeconds:39600,DaylighSavingTime:true},
	{Name:'Europe/Amsterdam', Country:'NL',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Andorra', Country:'AD',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Astrakhan', Country:'RU',Key:'MSK+1',Start:1459033200,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Europe/Athens', Country:'GR',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Belgrade', Country:'RS',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Berlin', Country:'DE',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Bratislava', Country:'SK',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Brussels', Country:'BE',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Bucharest', Country:'RO',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Budapest', Country:'HU',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Busingen', Country:'DE',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Chisinau', Country:'MD',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Copenhagen', Country:'DK',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Dublin', Country:'IE',Key:'GMT',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:true},
	{Name:'Europe/Gibraltar', Country:'GI',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Guernsey', Country:'GG',Key:'GMT',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Europe/Helsinki', Country:'FI',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Isle_of_Man', Country:'IM',Key:'GMT',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Europe/Istanbul', Country:'TR',Key:'TRT',Start:1473195600,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Europe/Jersey', Country:'JE',Key:'GMT',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Europe/Kaliningrad', Country:'RU',Key:'EET',Start:1414278000,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Kirov', Country:'RU',Key:'MSK',Start:1414274400,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Europe/Kyiv', Country:'UA',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Lisbon', Country:'PT',Key:'WET',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Europe/Ljubljana', Country:'SI',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/London', Country:'GB',Key:'GMT',Start:2147483647,OffsetInSeconds:0,DaylighSavingTime:false},
	{Name:'Europe/Luxembourg', Country:'LU',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Madrid', Country:'ES',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Malta', Country:'MT',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Mariehamn', Country:'AX',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Minsk', Country:'BY',Key:'MSK',Start:1301184000,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Europe/Monaco', Country:'MC',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Moscow', Country:'RU',Key:'MSK',Start:1414274400,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Europe/Oslo', Country:'NO',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Paris', Country:'FR',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Podgorica', Country:'ME',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Prague', Country:'CZ',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Riga', Country:'LV',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Rome', Country:'IT',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Samara', Country:'RU',Key:'SAMT',Start:1301180400,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Europe/San_Marino', Country:'SM',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Sarajevo', Country:'BA',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Saratov', Country:'RU',Key:'MSD',Start:1480806000,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Europe/Simferopol', Country:'UA',Key:'MSK',Start:1414274400,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Europe/Skopje', Country:'MK',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Sofia', Country:'BG',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Stockholm', Country:'SE',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Tallinn', Country:'EE',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Tirane', Country:'AL',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Ulyanovsk', Country:'RU',Key:'MSK+1',Start:1459033200,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Europe/Vaduz', Country:'LI',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Vatican', Country:'VA',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Vienna', Country:'AT',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Vilnius', Country:'LT',Key:'EET',Start:2147483647,OffsetInSeconds:7200,DaylighSavingTime:false},
	{Name:'Europe/Volgograd', Country:'RU',Key:'VOLT',Start:1609020000,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Europe/Warsaw', Country:'PL',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Zagreb', Country:'HR',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Europe/Zurich', Country:'CH',Key:'CET',Start:2147483647,OffsetInSeconds:3600,DaylighSavingTime:false},
	{Name:'Indian/Antananarivo', Country:'MG',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Indian/Chagos', Country:'IO',Key:'IOT',Start:820436400,OffsetInSeconds:21600,DaylighSavingTime:false},
	{Name:'Indian/Christmas', Country:'CX',Key:'CXT',Start:-1570084924,OffsetInSeconds:25200,DaylighSavingTime:false},
	{Name:'Indian/Cocos', Country:'CC',Key:'CCT',Start:-778410000,OffsetInSeconds:23400,DaylighSavingTime:false},
	{Name:'Indian/Comoro', Country:'KM',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Indian/Kerguelen', Country:'TF',Key:'TFT',Start:-315636840,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Indian/Mahe', Country:'SC',Key:'SCT',Start:-1577936472,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Indian/Maldives', Country:'MV',Key:'MVT',Start:-315636840,OffsetInSeconds:18000,DaylighSavingTime:false},
	{Name:'Indian/Mauritius', Country:'MU',Key:'MUT',Start:1238274000,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Indian/Mayotte', Country:'YT',Key:'EAT',Start:-865305900,OffsetInSeconds:10800,DaylighSavingTime:false},
	{Name:'Indian/Reunion', Country:'RE',Key:'RET',Start:-1577936472,OffsetInSeconds:14400,DaylighSavingTime:false},
	{Name:'Pacific/Apia', Country:'WS',Key:'WST',Start:1617458400,OffsetInSeconds:46800,DaylighSavingTime:false},
	{Name:'Pacific/Auckland', Country:'NZ',Key:'NZDT',Start:2147483647,OffsetInSeconds:46800,DaylighSavingTime:true},
	{Name:'Pacific/Bougainville', Country:'PG',Key:'BST',Start:1419696000,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Pacific/Chatham', Country:'NZ',Key:'CHADT',Start:2147483647,OffsetInSeconds:49500,DaylighSavingTime:true},
	{Name:'Pacific/Chuuk', Country:'FM',Key:'LMT',Start:-2147483648,OffsetInSeconds:35320,DaylighSavingTime:false},
	{Name:'Pacific/Easter', Country:'CL',Key:'EASST',Start:2147483647,OffsetInSeconds:-18000,DaylighSavingTime:true},
	{Name:'Pacific/Efate', Country:'VU',Key:'VUT',Start:727790400,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Pacific/Fakaofo', Country:'TK',Key:'TKT',Start:1325242800,OffsetInSeconds:46800,DaylighSavingTime:false},
	{Name:'Pacific/Fiji', Country:'FJ',Key:'FJT',Start:1610805600,OffsetInSeconds:43200,DaylighSavingTime:false},
	{Name:'Pacific/Funafuti', Country:'TV',Key:'LMT',Start:-2147483648,OffsetInSeconds:41524,DaylighSavingTime:false},
	{Name:'Pacific/Galapagos', Country:'EC',Key:'GALT',Start:728888400,OffsetInSeconds:-21600,DaylighSavingTime:false},
	{Name:'Pacific/Gambier', Country:'PF',Key:'GAMT',Start:-1806678012,OffsetInSeconds:-32400,DaylighSavingTime:false},
	{Name:'Pacific/Guadalcanal', Country:'SB',Key:'SBT',Start:-1806748788,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Pacific/Guam', Country:'GU',Key:'ChST',Start:977493600,OffsetInSeconds:36000,DaylighSavingTime:false},
	{Name:'Pacific/Honolulu', Country:'US',Key:'HST',Start:-712150200,OffsetInSeconds:-36000,DaylighSavingTime:false},
	{Name:'Pacific/Kanton', Country:'KI',Key:'EST',Start:788871600,OffsetInSeconds:46800,DaylighSavingTime:false},
	{Name:'Pacific/Kiritimati', Country:'KI',Key:'LINT',Start:788868000,OffsetInSeconds:50400,DaylighSavingTime:false},
	{Name:'Pacific/Kosrae', Country:'FM',Key:'KOST',Start:915105600,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Pacific/Kwajalein', Country:'MH',Key:'12',Start:745934400,OffsetInSeconds:43200,DaylighSavingTime:false},
	{Name:'Pacific/Majuro', Country:'MH',Key:'LMT',Start:-2147483648,OffsetInSeconds:41524,DaylighSavingTime:false},
	{Name:'Pacific/Marquesas', Country:'PF',Key:'MART',Start:-1806676920,OffsetInSeconds:-34200,DaylighSavingTime:false},
	{Name:'Pacific/Midway', Country:'UM',Key:'SST',Start:-1861879032,OffsetInSeconds:-39600,DaylighSavingTime:false},
	{Name:'Pacific/Nauru', Country:'NR',Key:'NRT',Start:287418600,OffsetInSeconds:43200,DaylighSavingTime:false},
	{Name:'Pacific/Niue', Country:'NU',Key:'NUT',Start:-173623200,OffsetInSeconds:-39600,DaylighSavingTime:false},
	{Name:'Pacific/Norfolk', Country:'NF',Key:'NFDT',Start:2147483647,OffsetInSeconds:43200,DaylighSavingTime:true},
	{Name:'Pacific/Noumea', Country:'NC',Key:'NCT',Start:857228400,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Pacific/Pago_Pago', Country:'AS',Key:'SST',Start:-1861879032,OffsetInSeconds:-39600,DaylighSavingTime:false},
	{Name:'Pacific/Palau', Country:'PW',Key:'LMT',Start:-2147483648,OffsetInSeconds:-54124,DaylighSavingTime:false},
	{Name:'Pacific/Pitcairn', Country:'PN',Key:'PST',Start:893665800,OffsetInSeconds:-28800,DaylighSavingTime:false},
	{Name:'Pacific/Pohnpei', Country:'FM',Key:'PONT',Start:-1806748788,OffsetInSeconds:39600,DaylighSavingTime:false},
	{Name:'Pacific/Port_Moresby', Country:'PG',Key:'LMT',Start:-2147483648,OffsetInSeconds:35320,DaylighSavingTime:false},
	{Name:'Pacific/Rarotonga', Country:'CK',Key:'CKT',Start:667992600,OffsetInSeconds:-36000,DaylighSavingTime:false},
	{Name:'Pacific/Saipan', Country:'MP',Key:'ChST',Start:977493600,OffsetInSeconds:36000,DaylighSavingTime:false},
	{Name:'Pacific/Tahiti', Country:'PF',Key:'TAHT',Start:-1806674504,OffsetInSeconds:-36000,DaylighSavingTime:false},
	{Name:'Pacific/Tarawa', Country:'KI',Key:'LMT',Start:-2147483648,OffsetInSeconds:41524,DaylighSavingTime:false},
	{Name:'Pacific/Tongatapu', Country:'TO',Key:'TOT',Start:1484398800,OffsetInSeconds:46800,DaylighSavingTime:false},
	{Name:'Pacific/Wake', Country:'UM',Key:'LMT',Start:-2147483648,OffsetInSeconds:41524,DaylighSavingTime:false},
	{Name:'Pacific/Wallis', Country:'WF',Key:'LMT',Start:-2147483648,OffsetInSeconds:41524,DaylighSavingTime:false},
	
];

export default timezones;